import 'vite/modulepreload-polyfill'
import Player from '@vimeo/player';
import AOS from 'aos';
import {Modal} from 'bootstrap';
import screenfull from 'screenfull';

$(function(){
  initializeVimeoModal();
});


function initializeVimeoModal(){
  let videoModal = $('#videoModal')[0];

  if(!videoModal){ return; }

  let modal = new Modal(videoModal);

  // Video functionality
  let vimeoPlayer = new Player($('#videoModal iframe')[0]);
  vimeoPlayer.on('play', function(data) {
    $('#videoModal iframe').css({"opacity": 1});
    if(!$(videoModal).hasClass("show")){
      vimeoPlayer.pause();
    }
  });

  vimeoPlayer.on('ended', function(data) {
    if($(videoModal).hasClass("show")){
      modal.hide();
      if(screenfull.isFullscreen){
        screenfull.exit();
      }
    }
  });

  videoModal.addEventListener('hide.bs.modal', () => {
    console.log("hide.bs.modal")
    vimeoPlayer.pause();
    setTimeout(function(){ vimeoPlayer.pause(); }, 300);
  });

  $(".watch-video-btn").click(function(e){
    modal.show();
    setTimeout(function(){
      vimeoPlayer.play();
    }, 350);
    return false;
  });
}
